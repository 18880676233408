.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 26px;
}

.switch-saas {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 21px;
}

.switch input,
.switch-saas input {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

.slider,
.slider-saas {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-top: 2px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-saas:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider,
input:checked + .slider-saas {
  background-color: var(--color-primary);
}

input:focus + .slider,
input:focus + .slider-saas {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before,
input:checked + .slider-saas:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round,
.slider-saas.round {
  border-radius: 34px;
}

.slider.round:before,
.slider-saas.round:before {
  border-radius: 50%;
}
