.sidebar-nav {
    height: 100%;
    width: 90%;
    margin-top: -14px;
    padding-top: 14px;
    background-color: #fff;
    position: fixed !important;
    left:0;
    z-index: 1;
    overflow: auto;
    -moz-box-shadow: 3px 0 5px 0 #555;
    -webkit-box-shadow: 3px 0 5px 0 #555;
    box-shadow: 3px 0 5px 0 #555;
    &.hide-sidebar {
        display: none;
    }
    .hide {
        display: none;
    }

    .show {
        display: block;
    }

    .bar-item {
        padding: 22px 36px;
        float: left;
        width: auto;
        border: none;
        display: block;
        outline: 0
    }

    .bar-btn {
        border: none;
        display: inline-block;
        padding: 22px 36px;
        overflow: hidden;
        text-decoration: none;
        text-align: left;
        color: #4F4F4F;
        background-color: var(--color-nav-gray-light);
        cursor: pointer;
        white-space: nowrap;        
        width:100%;
        font-size: 28px;
        &:hover {
            background-color: #4F4F4F33;
        }

        &.is-active{
            background-color: #4F4F4F33 !important;
            color: #4F4F4F;
            &:hover{
                background-color: var(--color-nav-gray-alt);
            }
        }

        &.is-nested {
            background-color: #f6f6f6 !important;
            &:hover{
                background-color: #ededed !important;
            }
        }
    }

    .toggle-icon {
        position: absolute;
        right: 10px;
        color: var(--color-base);
    }

    @media (min-width: 992px) {
        display: none;
    }
}