@font-face {
  font-family: 'Unilever Shilling';
  src: url('../../public/campaign-wizard/UnileverShilling.eot');
  src: local('Unilever Shilling'), local('UnileverShilling'),
    url('../../public/campaign-wizard/UnileverShilling.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/campaign-wizard/UnileverShilling.woff2') format('woff2'),
    url('../../public/campaign-wizard/UnileverShilling.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'UnileverShillingMd';
  src: url('../../public/campaign-wizard/UnileverShilling-Md.eot');
  src: local('Unilever Shilling Medium'), local('UnileverShilling-Md'),
    url('../../public/campaign-wizard/UnileverShilling-Md.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/campaign-wizard/UnileverShilling-Md.woff2')
      format('woff2'),
    url('../../public/campaign-wizard/UnileverShilling-Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
