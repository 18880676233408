.w-80 {
    max-width: 80% !important;
}

.w-44 {
    max-width: 44%;
}

.w-32 {
    width: 32%;
}