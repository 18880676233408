/* Header */
.cw-header-wrapper {
  height: 85px;
  margin-bottom: 28px;

  &--short {
    height: 45.43px;
    margin-bottom: 10px;
  }

  &--shrink {
    height: 60px;
    margin-bottom: 18px;
  }
  &--hide {
    display: none;
  }

  .nav-hamburger {
    //display: none;
    display: block;
    color: var(--color-primary);
    text-align: center;
    padding: 0px 24px !important;
    text-decoration: none;
    font-size: 14px;
    border-bottom: 1px solid #ccc;
    &:hover {
      color: #fff;
      background-color: var(--color-primary) !important;
    }

    @media (min-width: 992px) {
        display: none;
    }
  }
}

.cw-header-container {
  position: fixed;
  left: 0;
  right: 0;
  background: white;
  z-index: 100;
  border-top: 8px solid var(--color-dark);
}

.cw-header {
  border-bottom: 1px solid #ccc;
  padding-top: var(--space-10);
  padding-bottom: var(--space-10);
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  flex-grow: 1;
  &__text {
    display: flex;
    align-items: center;
    font-size: var(--font-size-h3);
    font-weight: 600;
    font-family: var(--font-primary);

    &--short {
      font-size: 20px;
    }
  }

  &--short {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  &--usermgmt {
    &:before {
      content: '\f013';
    }
  }

  .dropdown-menu {
    width: 270px;
    text-align: right;
  }

  .dropdown-menu.collapse {
    display: none;
  }

  .dropdown-menu.expand {
    display: block;
  }
  .dropdown-item.bold {
    font-weight: 600;
  }
  
  @media (max-width: 992px) {
    padding-left: 20px;
  }

  
}

.cw-logo {
  height: 64px;
  width: 60px;
  margin-right: var(--space-20);

  &--short {
    height: 32px;
    width: 30px;
    margin-right: var(--space-10);
  }

  &--shrink {
    height: 42px;
    width: 40px;
    margin-right: var(--space-10);
  }
}

.hrnew {
  border-top: 1px solid #ccc;
  width: 82%;
}
.brandidetails {
  list-style: none;
  position: relative;
}
.brandidetails::before {
  content: '• ';
  color: var(--color-primary);
  position: absolute;
  left: -15px;
  font-size: 18px;
}
.brandidetails-header {
  margin: 0 2%;
  position: relative;
}
.brandidetails-h2 {
  color: #007dbb;
  font-size: 30;
}
.brandidetails-close-btn {
  float: right;
  border-radius: 8px;
}
.brandidetails-show-master-icon {
  color: #e8a100;
}
.master-dialog {
  .brandidetails::before {
    line-height: 1.2;
  }
}
