.cw-dashboard-filter {
  //text-transform: capitalize;

  min-width: 165px;

  &__advance-search {
    min-width: 212px;
  }

  &.approver-readyonly-dropdown {
    min-width: 251px;
    &::placeholder {
      font-size: 16px !important;
    }
    font-size: 16px !important;
    .custom-tag-value {
      display: block;
      font-size: 16px;
      padding-bottom: 3px;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.country-search {
    width: 165px;
  }

  &.status-width {
    width: 255px;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */

    min-width: 200px;
  }
  @media (max-width: 1024px) {
    min-width: 138px;
    div[class*='-placeholder'] {
      font-size: 12px;
    }
  }
  div[class*='-control'] {
    min-height: unset;
    height: 38px;
  }

  height: 38px;
  font: 16px;

  .css-t3ipsp-control,
  .css-t3ipsp-control:hover,
  &:hover > css-t3ipsp-control {
    border-color: var(--color-grey);
    box-shadow: none;
  }

  .custom-tag-heading {
    color: #007dbb;
    display: block;
    font-size: 12px;
    width: 100%;
    // height: 10px;
    line-height: 0.7;
  }

  .custom-tag-value {
    display: block;
    font-size: 12px;
    padding-bottom: 3px;
    //position: relative;
    //top: -15px;
    max-width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      max-width: 93px;
    }
  }

  .css-1458gj0 {
    width: 100%;
    //padding: 1px 8px;
  }

  .css-1wy0on6 {
    height: 38px;
  }

  &.error > div,
  &.error:hover > div {
    border-color: var(--color-error);
  }

  div[class$='-option'] {
    box-shadow: 0 -1px 0 0 #eceeef;
    padding-top: 17px;
    padding-bottom: 17px;
    cursor: pointer;
    font: 16px;

    &:hover {
      background-color: var(--color-primary);
      color: white;
    }

    //&:active
  }

  div[class$='-menu'] {
    box-shadow: 0 0 0 1px var(--color-primary);
    margin-top: 1px;
    padding: 12px;
    width: 325px;
  }
  &.last-updated-filter {
    div[class$='-menu'] {
      width: 275px;
      @media (max-width: 1280px) {
        right: 0;
      }
      @media (max-width: 1024px) {
        right: 0;
      }
    }
  }
  &.report-updated-filter {
    div[class$='-menu'] {
      width: 275px;
    }
  }
  &.report-dashboard-filter {
    div[class$='-menu'] {
      width: 165px;
    }
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */

    div[class$='-menu'] {
      box-shadow: 0 0 0 1px var(--color-primary);
      margin-top: 1px;
      padding: 12px;
      width: 270px;
    }
  }

  .css-t3ipsp-control {
    border-color: var(--color-primary);

    &:hover {
      border-color: var(--color-primary);
    }
  }

  .css-170k14m,
  .css-1jro11w {
    min-width: 53px;
    width: 100%;
    height: 24px;
  }

  .css-13cymwt-control {
    height: 38px;
    min-height: unset !important;
    max-height: 38px;

    &:hover {
      border-color: var(--color-primary);
    }
  }
  // .css-1hwfws3{
  //   height: 38px;

  //   }
  .css-tr4s17-option,
  .css-151xaom-placeholder {
    color: var(--color-base);
  }

  .css-dpec0i-option {
    background-color: var(--color-table);
  }

  .css-tr4s17-option {
    background-color: var(--color-table);
  }

  .css-10wo9uf-option {
    &:nth-child(odd) {
      //background-color: var(--color-table);
    }
  }

  .css-es53b3-multiValue {
    .tagText {
      color: var(--color-primary);
      display: block;
      font-size: 12px;
    }

    .tagValue {
      display: block;
      font-size: 14px;
    }
  }

  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label {
    position: relative;
    padding-left: 31px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
  }

  [type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    background: #fff;
    box-shadow: inset 0px 0px 0px 2px var(--color-primary);
  }

  [type='checkbox']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    box-shadow: inset 0px 0px 0px 2px #ccc;
  }

  [type='checkbox']:checked + label:before,
  [type='radio']:checked + label:before {
    //border: solid #007dbb;
    box-shadow: inset 0px 0px 0px 2px var(--color-primary);
  }

  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 6px;
    height: 10px;
    border: solid #007dbb;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
  }

  [type='checkbox']:checked + label:after {
    opacity: 1;
  }
}

.cw-dashboard-reports-filter {
  //text-transform: capitalize;

  min-width: 165px;

  &__advance-search {
    min-width: 212px;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */

    min-width: 200px;
  }
  @media (max-width: 1024px) {
    min-width: 138px;
    div[class*='-placeholder'] {
      font-size: 12px;
    }
  }
  div[class*='-control'] {
    min-height: unset;
    height: 44px;
    padding-top: 5px;
  }

  height: 38px;
  font: 16px;

  .css-t3ipsp-control,
  .css-t3ipsp-control:hover,
  &:hover > css-t3ipsp-control {
    border-color: var(--color-grey);
    box-shadow: none;
  }

  .custom-tag-heading {
    color: #007dbb;
    display: block;
    font-size: 12px;
    width: 100%;
    // height: 10px;
    line-height: 0.7;
  }

  .custom-tag-value {
    display: block;
    font-size: 12px;
    padding-bottom: 3px;
    max-width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .css-1458gj0 {
    width: 100%;
    //padding: 1px 8px;
  }

  .css-1wy0on6 {
    height: 38px;
  }
}

.cw-dashboard-reports-filter-single {
  //text-transform: capitalize;

  min-width: 165px;

  &__advance-search {
    min-width: 212px;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */

    min-width: 200px;
  }
  @media (max-width: 1024px) {
    min-width: 138px;
    div[class*='-placeholder'] {
      font-size: 12px;
    }
  }
  div[class*='-control'] {
    min-height: unset;
    height: 44px;
  }

  height: 38px;
  font: 16px;

  .css-t3ipsp-control,
  .css-t3ipsp-control:hover,
  &:hover > css-t3ipsp-control {
    border-color: var(--color-grey);
    box-shadow: none;
  }

  .custom-tag-heading {
    color: #007dbb;
    display: block;
    font-size: 12px;
    width: 100%;
    // height: 10px;
    line-height: 0.7;
  }

  .custom-tag-value {
    display: block;
    font-size: 12px;
    padding-bottom: 3px;
    max-width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .css-1458gj0 {
    width: 100%;
    //padding: 1px 8px;
  }

  .css-1wy0on6 {
    height: 38px;
  }
}

.cw-dashboard--usercountry {
  //text-transform: capitalize;

  min-width: 165px;
  height: 38px;
  font: 16px;

  .css-t3ipsp-control,
  .css-t3ipsp-control:hover,
  &:hover > css-t3ipsp-control {
    border-color: var(--color-grey);
    box-shadow: none;
  }

  .custom-tag-heading {
    color: #007dbb;
    display: block;
    font-size: 12px;
    width: 100%;
    height: 10px;
  }

  .custom-tag-value {
    display: block;
    font-size: 14px;
    padding-bottom: 3px;
    //position: relative;
    //top: -15px;
  }

  .css-1458gj0 {
    width: 100%;
    //padding: 1px 8px;
  }

  .css-1hwfws3 {
    height: 38px;
  }

  &.error > div,
  &.error:hover > div {
    border-color: var(--color-error);
  }

  div[class$='-option'] {
    box-shadow: 0 -1px 0 0 #eceeef;
    padding-top: 17px;
    padding-bottom: 17px;
    cursor: pointer;
    font: 16px;

    &:hover {
      background-color: var(--color-primary);
      color: white;
    }

    //&:active
  }

  div[class$='-menu'] {
    box-shadow: 0 0 0 1px var(--color-primary);
    margin-top: 1px;
    padding: 12px;
    width: 100%;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */

    // div[class$='-menu'] {
    //   box-shadow: 0 0 0 1px var(--color-primary);
    //   margin-top: 1px;
    //   padding: 12px;
    //   width: 365px;
    // }
  }

  .css-t3ipsp-control {
    border-color: var(--color-primary);

    &:hover {
      border-color: var(--color-primary);
    }
  }

  .css-170k14m,
  .css-1jro11w {
    min-width: 53px;
    width: 100%;
    height: 24px;
  }

  .css-13cymwt-control {
    height: 38px;
    &:hover {
      border-color: var(--color-primary);
    }
  }

  .css-tr4s17-option,
  .css-151xaom-placeholder {
    color: var(--color-base);
  }

  .css-dpec0i-option {
    background-color: var(--color-table);
  }

  .css-tr4s17-option {
    background-color: var(--color-table);
  }

  .css-10wo9uf-option {
    &:nth-child(odd) {
      //background-color: var(--color-table);
    }
  }

  .css-es53b3-multiValue {
    .tagText {
      color: var(--color-primary);
      display: block;
      font-size: 12px;
    }

    .tagValue {
      display: block;
      font-size: 14px;
    }
  }

  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label {
    position: relative;
    padding-left: 31px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
  }

  [type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    background: #fff;
    box-shadow: inset 0px 0px 0px 2px var(--color-primary);
  }

  [type='checkbox']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    box-shadow: inset 0px 0px 0px 2px #ccc;
  }

  [type='checkbox']:checked + label:before,
  [type='radio']:checked + label:before {
    //border: solid #007dbb;
    box-shadow: inset 0px 0px 0px 2px var(--color-primary);
  }

  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 6px;
    height: 10px;
    border: solid #007dbb;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
  }

  [type='checkbox']:checked + label:after {
    opacity: 1;
  }
}

.cw-select--custom {
  //text-transform: capitalize;

  .css-t3ipsp-control,
  .css-t3ipsp-control:hover,
  &:hover > css-t3ipsp-control {
    border-color: var(--color-grey);
    box-shadow: none;
  }

  &.error > div,
  &.error:hover > div {
    border-color: var(--color-error);
  }

  div[class$='-option'] {
    box-shadow: 0 -1px 0 0 #eceeef;
    padding-top: 17px;
    padding-bottom: 17px;
    cursor: pointer;

    &:hover,
    &:active {
      background-color: var(--color-primary);
      color: white;
    }
  }

  div[class$='-menu'] {
    box-shadow: 0 0 0 1px var(--color-primary);
    margin-top: 1px;
    padding: 12px;
  }

  .css-t3ipsp-control {
    border-color: var(--color-primary);

    &:hover {
      border-color: var(--color-primary);
    }
  }

  .css-13cymwt-control {
    &:hover {
      border-color: var(--color-primary);
    }
  }

  .css-tr4s17-option,
  .css-151xaom-placeholder {
    color: var(--color-base);
  }

  .css-dpec0i-option,
  .css-tr4s17-option {
    background-color: var(--color-primary);
    color: white;
  }

  .css-10wo9uf-option {
    &:nth-child(odd) {
      background-color: var(--color-table);
    }
  }
}
.filter-item {
  flex: 1;
}
.cw-filter-fix-width {
  max-width: 165px;
}

.last-updated-filter,
.cw-dashboard__filters,
.cw-dashboard-reports-filter,
.cw-report-dashboard-wrapper {
  .css-1fdsijx-ValueContainer,
  .css-hlgwow,
  .css-hlgwow-ValueContainer {
    display: flex !important;
  }
}

.cw-dashboard-reports-filter,
.cw-report-dashboard-wrapper {
  .css-tr4s17-option {
    background-color: var(--color-primary);
  }
}

.reports-download-options {
  min-width: 350px;

  .custom-tag-value {
    max-width: 300px;
  }
}
