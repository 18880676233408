.sms-brand-details {
  .label-with-extra-text {
    display: flex;
    justify-content: space-between;
    gap: var(--space-10);
  }

  .POSCheck {
    @media (max-width: 1090px) {
      max-width: 400px;
      text-align: right;
      margin-bottom: var(--space-10);
    }
  }
}
.POSLabel {
  font-size: var(--text-base-size-sm);
}
