html,
body,
.pinkpurple {
  &.loginpage {
    background: rgb(183, 93, 177);
    background: linear-gradient(
      0deg,
      rgb(183, 93, 177) 0%,
      rgba(116, 112, 208) 100%
    );
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;

    @media (max-width: 767px) {
      .login-box--intro {
        display: none;
      }
      .nav-hamburger {
        display: block;
      }
    }

    .carousel {
      max-width: 377px;
      .carousel-indicators {
        bottom: 0;
      }
      .carousel-item {
        padding: 0 20px;
        height: 530px;
      }
      .carousel-caption {
        position: relative;
        padding: 0;
        left: 0;
        right: 0;
      }
      &--heading {
        font-size: var(--font-size-large);
        font-weight: 900;
        font-family: var(--font-primary-medium);
        padding-top: 45px;
      }
      &--subheading {
        padding: 20px 0 20px;
      }
      &--image {
        max-width: 320px;
        margin: 0 auto;
      }
      .carousel-indicators {
        button {
          height: 16.5px;
          width: 16.5px;
          background-color: var(--color-white);
          border-radius: 50%;
          display: inline-block;
          margin: 10px 15px;
          border: 0;
          &.active {
            background-color: var(--color-primary);
          }
        }
      }
    }

    .sso-card {
      max-width: 450px;
      height: 370px;
      padding: 20px;
      border-radius: 10px;
      .card-img-top {
        margin: 0 auto;
        margin-top: 20px;
        width: 95px;
      }
      .card-title {
        color: var(--color-primary);
      }
      .card-text {
        font-size: var(--font-size-h3);
      }
      .btn {
        font-size: var(--text-small2);
        margin-top: 40px;
      }
    }

    .login-progress-box {
      display: flex;
      flex-direction: column;
      // margin: 15% auto 0;
      width: 440px;
      padding: 44px 44px 177.5px 44px;
      gap: 98.5px;
      background: #fff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);

      &_header {
        display: flex;
        align-items: center;

        h3 {
          margin-left: 6px;
          color: var(--color-primary);
          text-align: center;
          //font-family: Unilever Shilling;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      &_logo {
        width: 48.347px;
        height: 49.753px;
      }
      &_loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        .loader-img {
          width: 86px;
          height: 86px;
        }
        h3 {
          margin-top: 30px;
          color: #4f4f4f;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
  .loginpage + .cw-print-hide .hkctBI {
    margin-top: 0;
  }
  /** Header menu */
  .bell-notification {
    padding-left: 50%;
    img {
      margin-top: 1px;
      max-width: 24px;
      max-height: 28px;
    }
    .bell-icon {
      fill: var(--color-primary);
    }
  }
  .user-menu {
    font-family: var(--font-primary);
    .show > .btn-primary.dropdown-toggle {
      background-color: var(--color-primary);
    }

    .dropdown-menu::before {
      border-bottom: 9px solid rgba(0, 0, 0, 0.2);
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      right: 3%; /* position */
      position: absolute;
      top: -8px;
    }

    .dropdown-menu::after {
      border-bottom: 8px solid #ffffff;
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      right: 3%; /* position */
      position: absolute;
      top: -7px;
    }
    .navbar-expand {
      padding: 0;
      .navbar-nav {
        flex-direction: column;
        width: 100%;
      }
    }

    .dropdown-menu {
      width: 302px;
      -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.53);
      -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.53);
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.53);
      border-radius: 10px;
      margin-top: 10px;
      padding-bottom: 0;

      .label,
      .label-second {
        display: inline-block;
        text-align: left;
        width: 100%;
        font-weight: bold;
        font-size: var(--text-small);
        padding-bottom: 5px;
        color: var(--color-nav-text);
      }
      .label-second {
        padding: 10px 0 3px 13px;
      }

      .user-dropdown-item {
        display: flex;
        flex-direction: row;
        text-align: left;
        width: 100%;
        padding-left: 15px;
        align-items: center;
        .user-dropdown-left {
          width: 20%;
          align-items: center;
          display: flex;
        }
        .user-dropdown-right {
          width: 80%;
        }
        .email {
          white-space: nowrap;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          margin-bottom: 0;
          font-size: var(--text-xsmall);
        }
        .full-name {
          white-space: nowrap;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          margin-bottom: -6px;
          font-size: var(--text-small);
        }
        .user-icon {
          max-width: 24px;
          margin-left: 4px;
          &-logout {
            width: 20px;
            height: 20px;
            margin-left: 5px;
          }
        }
        .role-name {
          padding-left: 17px;
          font-size: var(--text-small);
          color: var(--color-nav-text);
          font-family: var(--font-primary);
        }
        .user-logout-label {
          padding-left: 20px !important;
        }
      }
    }
    .avatar {
      width: 32px;
      border-radius: 100%;
      text-align: center;
      background-color: var(--color-primary);
      display: inline-block;
      padding: 0;
      line-height: 30px;
    }
    .avatar span {
      color: var(--color-white);
      font-weight: normal;
      font-size: 15px;
    }
    .avatar::after {
      content: none;
    }
    .accordion-nav {
      padding: 0;
      text-align: left;
      .accordion-collapse.show {
        background-color: var(--color-nav-gray-light);
      }
      &.dropdown-item:hover {
        background-color: var(--color-white);
        border-radius: 9px;
      }
      .accordion-button {
        width: 100%;
        border: 0;
        text-align: left;
        padding: 5px 5px 5px 25px;
        background-color: var(--color-nav-gray-alt);
        &.collapsed {
          background-color: var(--color-white);
          &:hover {
            background-color: var(--color-nav-gray);
            border-radius: 9px;
          }
        }
      }
      .accordion-button:hover {
        background-color: var(--color-nav-gray-alt);
      }
      .nav-link:hover {
        background-color: var(--color-nav-gray) !important;
        border-radius: 9px;
      }
      .nav-link {
        padding-left: 25px;
      }
      &.dropdown-item.logout {
        padding-left: 25px;
        padding-top: 4px;
        padding-bottom: 4px;
        &:hover {
          background-color: var(--color-nav-gray);
          border-radius: 9px;
        }
      }
    }
  }
  .user-accordion {
    .accordion-button {
      position: relative;
      &.collapsed::after {
        content: ' ';
        display: inline-block;
        border-bottom: 2px solid var(--color-black);
        border-right: 2px solid var(--color-black);
        height: 10px;
        width: 10px;
        transform: rotate(45deg);
        margin-left: -33px;
        position: absolute;
        top: 40%;
        right: 20px;
      }
      &::after {
        content: ' ';
        display: inline-block;
        border-bottom: 2px solid var(--color-black);
        border-right: 2px solid var(--color-black);
        height: 10px;
        width: 10px;
        transform: rotate(-135deg);
        margin-left: -33px;
        position: absolute;
        top: 50%;
        right: 20px;
      }
    }
  }

  .bell-notification-container {
    margin-right: 40px;
  }

  .header-nav {
    margin-left: 20px;
    .navbar-nav {
      flex-direction: row;
    }

    .dropdown-menu::before {
      border-bottom: 9px solid rgba(0, 0, 0, 0.2);
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      left: 3%; /* position */
      position: absolute;
      top: -8px;
    }

    .dropdown-menu::after {
      border-bottom: 8px solid #ffffff;
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      left: 3%; /* position */
      position: absolute;
      top: -7px;
    }

    .header-dropdown-title {
      font-size: 14px;
    }

    .dropdown-menu {
      width: 302px;
      -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.53);
      -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.53);
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.53);
      border-radius: 10px;
      margin-top: 10px;
      padding-bottom: 0;
      text-align: left;
      font-size: 14px;
      .label,
      .label-second {
        display: inline-block;
        text-align: left;
        width: 100%;
        font-weight: bold;
        font-size: var(--text-small);
        padding-bottom: 5px;
        color: var(--color-nav-text);
      }
      .label-second {
        padding: 10px 0 3px 13px;
      }

      .header-dropdown-item {
        padding: 1rem 1.5rem;
        width: 100%;
        text-align: left;
        font-size: 14px;
        color: #4f4f4f;
        &:hover {
          background-color: #4f4f4f33 !important;

          a {
            background-color: rgba(220, 220, 220, 1) !important;
          }
        }
        &:active {
          background-color: #4f4f4f33 !important;
          color: #4f4f4f;
        }

        .icon.external-link-icon {
          margin-top: -5px;
        }
      }
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  .approver-list-btn {
    display: block;
    margin-top: 10px;
  }
}
