@media print {
  /* styles go here */
  .collapse:not(.show) {
    display: block;
  }

  .cw-print-text {
    text-decoration: none !important;
  }

  .cw-print-hide {
    display: none;
  }

  .cw-print-show {
    display: block;
  }
  .cw-striped-row {
    padding: 5px 13px;
  }

  .collapse-h,
  .collapse-h pt-2,
  .col-sm-6.text-right,
  .cw-color--primary active {
    display: none;
  }
}

.campaign-break-word {
  word-break: break-all;
  display: inline-block;
  max-width: 80%;
}

.menu-grab-icon {
  margin-right: 15px;
}
.sorting-menu-list-heading-icon {
  padding: 14px 20px;
  background-color: #f9f9f9;
}
.sorting-menu-list-heading-icon + h5 {
  margin: 10px 0 0 0;
  font-weight: bolder;
  font-size: 16px;
}
.answer-heading {
  padding: 0 50px;
  margin-top: 20px;
}
.answers-list {
  margin: 0 25px 15px 50px;
  .answers-list-item {
    margin: 0 !important;
    color: var(--color-primary);
    @extend .sorting-menu-list-heading-icon;
  }
}
.sorting-list {
  background-color: #f9f9f9 !important;
}
.sorting-title-header {
  padding: 14px 0px;
  background-color: #ffffff;
}
