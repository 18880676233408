.__react_component_tooltip {
  &.cw-toolip {
    text-align: left;
    background-color: var(--color-primary);
    max-width: 210px;
    font-size: 14px;
    line-height: 18px;
    padding: 15px;
  }

  .multi-line {
    text-align: left;
  }
}

.__react_component_tooltip {
  &.cw-tooltip {
    text-align: left;
    background-color: var(--color-primary);
    width: 30%;
    font-size: 14px;
    line-height: 18px;
    padding: 1% 1% 1% 2%;
    border-radius: 1em;
    text-transform: none !important;
  }

  &.cw-email-tooltip {
    background-color: var(--color-base) !important;
    padding: 1% 1% 1% 1%;

    &.place-right:after {
      border-right-color: var(--color-base) !important;
    }

    &.place-bottom:after {
      border-bottom-color: var(--color-base) !important;
    }
  }

  &.cw-email-tooltip__help {
    padding: 1% 1% 1% 1%;
  }

  .multi-line {
    text-align: left;
  }

  &.cw-consent-tooltip {
    color: var(--color-primary);
    font-weight: normal;
  }
}

.__react_component_tooltip {
  &.cw-tooltip-error {
    text-align: left;
    background-color: #f7ebf9;
    color: #ad19cc;
    width: 30%;
    font-size: 14px;
    line-height: 18px;
    padding: 1% 1% 1% 2%;
    border-radius: 1em;
  }

  .multi-line {
    text-align: left;
  }
}

.__react_component_tooltip {
  &.cw-tooltip-sms {
    text-align: left;
    background-color: var(--color-primary);
    font-size: 14px;
    line-height: 18px;
    padding: 15px;
    width: 32%;
    border-radius: 20px;
  }

  .multi-line {
    text-align: left;
  }
}

.__react_component_tooltip.type-dark {
  display: none !important;
}
.custom-cw-toolip {
  width: 100%;
  max-width: 400px !important;
  border-radius: 15px !important;
  .multi-line {
    text-align: justify !important;
  }
}

.master_data_notice_tooltip {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
}
.__react_component_tooltip.type-info.tooltip-light {
  background-color: var(--color-white);
  box-shadow: 0px -3px 15px -3px var(--color-nav-text);
  -webkit-box-shadow: 0px -3px 15px -3px var(--color-nav-text);
  -moz-box-shadow: 0px -3px 15px -3px var(--color-nav-text);
  color: var(--color-nav-text);
  font-size: var(--text-xsmall);
  border-radius: 10px;
  z-index: 1001;
}
.__react_component_tooltip.type-info.tooltip-light.place-bottom:after {
  border-bottom-color: var(--color-white);
}
