p,
ul,
li {
  margin: 0;
  padding: 0;
}

a {
  color: var(--color-primary);

  &:hover {
    color: var(--color-primary);
  }
}

label {
  display: block;
}

.container {
  background-color: white;
  //background-size: 100% 145px;
  //padding-bottom: 238px;
}

// Badge
.badge {
  color: invert($color: black);
  font-size: var(--text-xsmall);
  line-height: var(--line-height-xsmall);
  font-weight: 700;
  padding: 5px 13px;
  height: 22px;
  min-width: 89px;
  text-align: center;
}

// TODO: Introduce status class names rather overriding badge default colors.
.badge-warning {
  background-color: var(--color-processing);
}

.badge-success {
  background-color: var(--color-completed);
}

.badge-secondary {
  background-color: var(--color-cancelled);
}

.badge-primary {
  background-color: var(--color-draft);
}

.badge-red {
  background-color: var(--color-error);
}

// Pagination
.page-link {
  color: var(--color-primary);
  font-size: var(--text-small);
  line-height: var(--line-height-small);
}

.page-item.active .page-link {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

.page-item .page-link {
  border-color: var(--color-border);
}

.page-item.disabled .page-link {
  color: var(--color-border);
}

// Buttons
.btn {
  font-size: 16px;
  font-weight: bold;
  line-height: 38px;
  padding: 0 21px;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  color: var(--color-white);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn-outline-primary {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn-outline-secondary {
  color: var(--color-base);
  border-color: var(--color-grey);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  color: var(--color-white);
  border-color: var(--color-border-btn);
  background-color: var(--color-border-btn);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active {
  color: var(--color-base);
  background-color: var(--color-background-btn-secondary);
  border-color: var(--color-grey);
}

// Forms
.form-check {
  padding-left: 0.2rem;
}

.form-group {
  position: relative;
}

// Margin Utility
.mb-5 {
  margin-bottom: var(--space-5) !important;
}

// Padding Top
.pt-5 {
  padding-top: var(--space-5) !important;
}
@media (min-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
  padding-right: 0.75rem !important;
  border-color: var(--color-error);
  &:focus {
    border-color: var(--color-error);
    box-shadow: 0 0 0 0.2rem var(--color-input-border-highlight-invalid);
  }
}
.badge-email-reject {
  background-color: var(--color-error);
}
.badge-email-schedule {
  background-color: var(--color-schedule);
}
.badge-email-awaiting-setup {
  background-color: var(--color-awiting-setup);
}
.badge-email-awaiting-info {
  background-color: var(--color-email-badge-info);
}
.badge-email-awaiting-purchase_order {
  background-color: var(--color-awiting-purchase-order);
}
