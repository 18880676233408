.status-progress-wrapper {
  width: 300px;
}
.status-progress {
  position: relative;
  padding-left: 45px;
  list-style: none;

  &__sub-title {
    font-size: 14px;
    color: var(--color-grey-number) !important;
    font-weight: 400;
  }
}
.steps-item {
  position: relative;
  padding-left: 20px;
  color: #707070;
}
.steps-item:not(:last-child) {
  padding-bottom: 45px;
}
.steps-item::before {
  display: inline-block;
  content: '';
  position: absolute;
  left: -25px;
  height: 100%;
  width: 10px;
}
.steps-item::after {
  content: url('../../public/campaign-wizard/time-later.svg');
  display: inline-block;
  position: absolute;
  top: 0;
  left: -45px;
  width: 40px;
  height: 40px;
}
.steps-item::before {
  border-left: 2px dashed #9e9e9e;
}
.steps-item.is-done::before {
  border-left: 2px solid #9e9e9e;
}
.steps-item.is-done::after {
  content: url('../../public/campaign-wizard/time-done.svg');
  font-size: 10px;
  color: #fff !important;
  text-align: center;
}
.steps-item span {
  color: #666;
}
.steps-item.is-done {
  color: var(--color-base);
}
.steps-item.is-done span {
  color: var(--color-base);
}
.steps-item.is-now {
  color: var(--color-base);
}
.steps-item.is-now span {
  color: #ff9900;
  font-weight: 600;
}
.steps-item.is-now::after {
  content: url('../../public/campaign-wizard/time-now.svg');
  top: 0px;
  text-align: center;
  color: #ff9900;
}

.status-progress strong {
  display: block;
  font-weight: 600;
}
.steps-item:last-child .step-down-arrow {
  display: none;
}
.step-down-arrow {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #9e9e9e;
  position: absolute;
  bottom: 0;
  left: -31px;
}
.steps-item:last-child:before {
  display: none;
}
.insidesubtitle {
  font-size: 14px;
}

.email-status-timeline {
  width: 100%;
  .status-progress {
    strong {
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      line-height: 17px;
    }

    &__sub-title {
      font-size: 14px;
      color: var(--color-grey-number) !important;
      font-weight: 400;
      line-height: 19px;
    }

    .steps-item {
      span {
        color: var(--color-grey-8);
        font-weight: 400;
      }
      &.is-done {
        span {
          color: var(--color-grey-8);
          font-weight: 400;
        }
      }
      &.is-now {
        span {
          color: var(--color-orange-2);
          font-weight: 400;
        }
      }
      &.is-later {
        strong {
          color: var(--color-grey-number);
        }
      }
    }

    .step-height {
      height: 99px;
    }

    .insidesubtitle {
      font-size: 12px;
      line-height: 13px;
    }

    .step-line-height {
      line-height: 19px;
    }
  }
}
