.btn {
  font-size: var(--text-base-size);
  font-weight: bold;
  line-height: 38px;
  padding: 0 21px;

  &-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-white);

    &-link {
      display: inline;
      padding: 0;
      border: none;
      color: var(--color-primary);
      font-size: inherit;
      font-weight: normal;
      line-height: inherit;
      vertical-align: inherit;

      &:hover {
        color: var(--color-primary);
      }
    }
  }
  &-secondary {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }

  &-outline-primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
  }

  &-outline-secondary {
    color: var(--color-base);
    border-color: #cccccc;

    &:hover,
    &:focus,
    &:active {
      color: var(--color-base);
      background-color: var(--color-background-btn-secondary);
      border-color: #cccccc;
    }
  }

  &.btn-outline-primary.disabled,
  .btn-outline-primary:disabled,
  &:disabled {
    background-color: #fff;
    border-color: #6f6868;
    color: #6f6868;
    opacity: 0.65;
    cursor: not-allowed;
  }

  &--homepage-btn {
    width: 100%;
    border-radius: 10px;
    font-weight: 100;
    font-size: 14px;
    padding: 0 45px;
  }
  &--homepage-secondary {
    width: 100%;
    border-radius: 10px;
    font-weight: 100;
    font-size: 14px;
    background-color: #fff;
    padding: 0 45px;
  }
}
.cw-btn-home-wrap {
  display: inline-block;
}
.btn-custom-width {
  width: 175px;
}

.cw-new-update-message {
  background-color: #f7e8f7;
  opacity: 0.8;
  border-radius: 15px;
  border: none;
  color: #bb22bb;
  padding: 2% 2% 2% 3%;
  &-new-message-margin {
    margin: 14% 0 0 13%;
  }
}
.btn-sms-custom-width {
  width: 220px;
  border-radius: 10px;
}
.btn-block + .btn-block {
  margin-top: 1.5rem;
}

.btn-circle.btn-md {
  width: 40px;
  height: 40px;
  padding: 1px 10px;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
}

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}

.btn-circle.btn-remove {
  background-color: var(--color-primary);
  width: 26px;
  height: 26px;
  border: none;
  border-radius: 15px;
  color: var(--color-white);
  padding: 0px 5px;
  font-size: 13px;
  cursor: pointer;
}
