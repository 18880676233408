.square-button {
  width: 24px;
  height: 24px;
  background-color: #007dbb;
  color: white;
  border: none;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 0px;
  font-size: 12px;
  cursor: pointer;
}

.square-button-disabled {
  width: 24px;
  height: 24px;
  background-color: #707070;
  color: white;
  border: none;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 0px;
  font-size: 12px;
  cursor: pointer;
}

.square-button > svg {
  margin-right: 10px;
}
.empty-colors {
  align-self: center;
  padding: 2px;
  color: #707070;
  padding-left: 10px;
  height: 30px;
  padding-right: 10px;
  font-size: 14px;
}
.brand-color-sample {
  width: 96px;
  height: 35px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  border-radius: 2px;
  margin-right: 7px;
}

.brand-color-delete {
  display: none;
  position: absolute;
  top: -10px;
  right: -2px;
  color: gray;
  background: white;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}

.brand-color:hover .brand-color-delete {
  display: block;
}

.brand-color-container {
  max-width: 100px;
}
.brand-color {
  position: relative;
}

.brand-color-title {
  font-size: 14px;
  text-align: center;
  color: #707070;
}

.brand-color-add-button-large {
  width: 96px !important;
  border: 1px solid #ccc !important;
  margin-right: 10px;
}

.brand-color-add-button {
  width: 35px;
  height: 35px;
  margin-top: 0px;
  padding-top: 0px;
  border: 2px solid #007dbb;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.brand-color-add-button-disabled {
  width: 35px;
  height: 35px;
  margin-top: 0px;
  padding-top: 0px;
  border: 2px solid #707070;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.description {
  font-size: 14px;
  color: #707070;
}

.color-picker-add-button {
  font-weight: normal;
  height: 25px;
  font-size: 12px;
  line-height: 14.5px;
  border-radius: 2px;
}

.color-picker-close-button {
  margin-top: 5px;
  font-size: 12px;
  line-height: 14.5px;
}

.sketch-picker-new {
  box-shadow: none !important;
}

.color-picker-container .error {
  font-size: var(--text-small);
}

.color-picker-close {
  position: absolute;
  right: -17px;
  top: -13px;
  background: var(--color-white);
  padding: 0px 5px;
  border: none;
  border-radius: 50%;
  z-index: 10;
}
