.cw-singlesample-text {
  margin: 0% 0 2.5% 4%;
}

.cw-singleSample-text {
  margin: 0% 0 0.5% 2%;
}

.cw-singleSampleText {
  margin: 0 0 0 10%;
  width: 97%;
}

.cw-singleSampleTextBox {
  margin: 1% 0 0 1%;
  width: 100%;
}
.cw-promotigo-btn-submit {
  height: 54px;
  width: 16%;
}

.cw-addSample {
  margin: 3% 0 0 3%;
}

.cw-deleteSample {
  margin: 3% 0 0 1%;
}

.cw-win-lose-margin {
  margin: 0 0 0 -6%;
}
