.tox .tox-statusbar {
  align-items: center;
  background-color: #fff;
  border-top: 0px solid #ccc !important;
  color: rgba(34, 47, 62, 0.7);
  display: none !important;
  flex: 0 0 auto;
  font-size: 12px;
  font-weight: 400;
  height: 18px;
  overflow: hidden;
  padding: 0 8px;
  position: relative;
  text-transform: uppercase;
}

.tox .tox-notification {
  min-width: 700px;
}

.tox.tox-tinymce {
  border-radius: 0;
}

.tox.tox-tinymce-aux .tox-collection--list .tox-collection__item--active {
  background-color: #007dbb;
}
