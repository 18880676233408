.font-selector {
  margin-bottom: 20px;

  & .name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 22px;
    color: #000000;
  }

  & .label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 11px;
    color: #000000;
  }
}

.react-select-font__menu {
  display: block;
}
