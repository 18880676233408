.design-email {
  background: white;
  border: 0.5px solid var(--color-range-slider-grey);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 600px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1400px) {
    & {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  button {
    position: absolute;
    margin-top: 75px;
    margin-bottom: 45px;
  }

  img {
    margin-top: calc(119px + 25%);
    width: 90px;
    height: 90px;
  }

  p {
    margin-top: 35px;
    font-family: 'Inter';
    font-size: var(--text-small);
    line-height: 17px;
    text-align: center;

    color: var(--color-grey-number);
  }
}
