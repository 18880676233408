.cw-campaign {
  &__advance-search-section {
    border: 1px solid var(--color-search-border);
    padding: 20px;
    background-color: var(--color-table);
  }

  &__advance-search-space {
    border-bottom: 2px solid var(--color-grey);
    margin: 30px -42px 0;
  }
}

#states-autocomplete {
  &:hover,
  &:focus {
    border-color: var(--color-primary);
  }
}

.menu {
  position: absolute;
  background: white;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  border-color: var(--color-primary);
  border-radius: 2px;
  z-index: 100;
  max-height: 200px;
  overflow-y: scroll;
}

.suggestion-item {
  padding: 2px 6px;
  margin: 2px 6px;
  display: flex;
  cursor: default;
  background-color: white;
  border-bottom: 1px solid var(--color-search-border);
  color: black;

  &:hover {
    color: var(--color-white);
    background-color: var(--color-primary);
  }

  p:first-child {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: var(--space-15);
  }
}

.close {
  font-family: 'Font Awesome 5 Free';
  content: '\f00d';
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
}

.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  position: relative;
  left: -28px;
  top: -3px;
  bottom: 0;
  width: 15px;
  height: 15px;
  z-index: 1;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS */
    top: -15px;
  }
}
.close-icon {
  border: none;
  color: grey;
  cursor: pointer;
  width: 15px;
  left: -28px;
  position: relative;
  background: transparent;
  vertical-align: middle;
  z-index: 1;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS */
    top: -15px;
  }
}

.filter-icon {
  height: 22px;
  margin-right: var(--space-15);
}

.filter-icon-btn {
  color: var(--color-primary);
}

#campaignIdAutocomplete::-ms-clear,
#campaignNameAutocomplete::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

// .close-icon:after {
//     font-family: "Font Awesome 5 Free";
//     content: "\f00d";
//     display: block;
//     width: 15px;
//     height: 15px;
//     z-index: 1;
//     margin: auto;
//     border-radius: 50%;
//     text-align: center;
//     color: black;
//     font-weight: normal;
//     font-size: 12px;
//     cursor: pointer;
// }

.hidden {
  visibility: hidden;
}

.search-input {
  width: 100%;
  font-size: var(--text-small);
  line-height: var(--line-height-small);
  height: 35px;
  border: 1px solid hsl(0, 0%, 80%);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right-width: 0;
  padding-left: var(--space-10);
  padding-right: 22px;
  &:hover,
  &:focus {
    border-color: var(--color-primary);
  }
  &::placeholder {
    font-size: 14px;
    color: var(--color-text-grey-3);

    @media (min-width: 992px) {
      font-size: 12px;
    }
  }
}

.search-input-wrapper {
  position: relative;
  display: inline-block;
  width: 50%;

  @media (min-width: 1024px) {
    width: 40%;
  }
}

.search-input-wrapper-admin {
  position: relative;
  display: inline-block;
  width: 60%;

  @media (min-width: 1024px) {
    width: 62%;
  }
}

.search-button {
  padding: 2px 24px;
  height: 35px;
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: var(--color-primary);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid var(--color-primary);

  img {
    height: var(--space-15);
  }
}

#campaignIdAutocomplete:not(:valid) ~ .close-icon,
#campaignNameAutocomplete:not(:valid) ~ .close-icon {
  display: none;
}
