.email-address-field {
    color: #373a3c;
    font-family: "Open Sans";
    font-size: 16px;
    padding-top: 16px;
    .email-address-label {
        padding-bottom: 14px;
    }
    .email-input-line {
        .email-input-with-new {
            border: 1px solid #cccccc;
            border-radius: 4px;
            width: 540px;
            line-height: 22px;
            display: inline-flex;
            .email-input {
                border: white;
                width: 500px;
                height: 38px;
                padding-left: 10px;
            }
            .new-box {
                height: 22px;
                width: 50px;
                color: #ffffff;
                font-size: 12px;
                font-weight: bold;
                line-height: 12px;
                text-align: center;
                border-radius: 4px;
                background-color: #f0ad4e;
                padding-top: 4px;
                margin-right: 8px;
                margin-top: 8px;
            }
        }
        .add-or-delete {
            display: inline-block;
            color: #007dbb;
            padding-left: 22px;
            font-size: 20px;
            cursor: pointer;
        }
    }
    .email-error {
        padding-top: 24px;
        padding-bottom: 24px;
        display: flex;
        color: #df1642;
        .error-icon {
            font-size: 20px;
            padding-right: 10px;
            position: relative;
            bottom: 14%;
        }
        .error-text {
            width: 490px;
            display: inline-block;
            line-height: 22px;
        }
    }
    .email-suggestions-line {
        width: 540px;
        padding: 14px 10px;
        border: 1px solid #007dbb;
        border-radius: 4px;
        overflow-y: auto;
        max-height: 314px;
        background-color: #fff;
        position: absolute;
        z-index: 1;
        .suggested-email-id {
            cursor: pointer;
            padding: 20px 8px;
            height: 56px;
            box-shadow: 0 -1px 0 0 #eceeef;
            letter-spacing: 0;
            line-height: 24px;

            &:nth-child(odd) {
                background-color: #f9f9f9;
            }
            &:hover {
                background-color: #007dbb;
            }
        }
    }
}
