.cw-releasenote-wrapper {
  .timeline {
    &__heading {
      color: var(--color-primary);
      font-size: var(--font-size-h3);
      padding: 15px 0 30px;
    }

    .ui.year-accordion:not(.styled) {
      .item .content:not(.ui) {
        padding: 0;
      }
    }
    &__title {
      padding: 12px 0px;
    }
    .ui.year-accordion .title:not(.ui) {
      padding: 12px 0 10px 12px;
      font-size: var(--text-base-size);
    }

    .year-accordion {
      position: relative;
      padding-left: 40px;
      .item:hover {
        color: inherit;
        background-color: inherit;
      }
      .item::before {
        content: '';
        height: 23px;
        width: 23px;
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid var(--color-primary);
        position: absolute;
        left: -37px;
        z-index: 1;
        top: 12px;
      }
      .item {
        background-color: #fff;
        position: relative;
        .title {
          background-color: rgb(237, 237, 237);
          border-bottom: 1px solid var(--color-primary);
          i {
            position: absolute;
            width: 38px;
            height: 23px;
            border-left: 1px solid;
            padding: 3px 5px 0 9px;
            color: var(--color-base);
            right: 9px;
            top: 12px;
          }
        }
      }
      .item .title::before,
      .item:last-child .title.active::before {
        content: '';
        position: absolute;
        top: 12px;
        left: -26px;
        height: 100%;
        width: 2px;
        border-left: 2px dotted var(--color-primary);
      }
      .item:last-child .title::before {
        height: 40px;
      }
      // .time-content::before {
      //   content: '';
      //   position: absolute;
      //   top: 25px;
      //   left: 18px;
      //   height: 105%;
      //   width: 2px;
      //   border-left: 2px dotted var(--color-primary);
      // }
      .time-content:last-child::before {
        height: 95%;
      }
      .time-content:last-child .timeline-item {
        margin-bottom: 40px;
      }
      .time-content {
        position: relative;
        ul {
          padding-left: 50px;

          &:not(.timeline-item) {
            padding-left: var(--space-15) !important;
            margin-block: var(--space-15);
            li {
              list-style: initial;
            }
          }
        }

        ol {
          margin-block: var(--space-15);
          padding-left: var(--space-15) !important;
          li {
            list-style: auto;
          }
        }
      }
    }
  }
}

.timeline-item {
  li {
    list-style: none;
  }

  &__quarter:before,
  &__section.month:before {
    content: '';
    height: 21px;
    width: 21px;
    background-color: var(--color-primary);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: -32px !important;
    z-index: 1;
    margin-top: 4px;
  }
  &__section.month:before {
    height: 14px;
    width: 14px;
    left: 12px;
  }

  &__section {
    margin-bottom: 15px;
  }
  &__month {
    color: var(--color-email-dark-pink);
    text-transform: uppercase;
    padding-bottom: 5px;
    font-weight: bold;
  }
  &__title {
    color: var(--color-base);
    font-weight: bold;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      height: 8px;
      width: 8px;
      left: -29px !important;
      margin-top: 8px;
      background-color: var(--color-primary);
      border-radius: 50%;
      z-index: 1;
    }
  }
  &__desc {
    color: var(--color-base);

    p {
      margin: revert;

      &:first-child {
        margin-top: 5px;
      }
    }
  }

  &__quarter {
    color: var(--color-base);
    font-weight: bold;
    font-size: var(--font-size-h3);
    padding-bottom: 8px;
    margin: 30px 0px 20px;
    border-bottom: 1px solid var(--color-primary);
  }

  .badge.warning {
    background-color: var(--color-email-warning);
  }

  .read-more-link {
    color: var(--color-primary);
  }
}

.quarter-accordion {
  margin-left: 50px;
  padding-bottom: 20px;

  .ui.sub-accordion:not(.styled) {
    .item .content:not(.ui) {
      padding: 0;
    }
  }
  &__title {
    padding: 12px 0px;
  }
  .ui.sub-accordion .title:not(.ui) {
    padding: 12px 0 10px 12px;
    font-size: var(--text-base-size);
  }

  .sub-accordion {
    position: relative;
    padding-left: 40px;
    .item:hover {
      color: inherit;
      background-color: inherit;
    }
    .item::before {
      content: '';
      height: 23px;
      width: 23px;
      background-color: var(--color-primary) !important;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid var(--color-primary);
      position: absolute;
      left: -37px;
      z-index: 1;
      top: 12px;
    }
    .item {
      background-color: #fff;
      position: relative;
      .title {
        background-color: rgb(237, 237, 237);
        border-bottom: 1px solid var(--color-primary);
        i {
          position: absolute;
          width: 38px;
          height: 23px;
          border-left: 1px solid;
          padding: 3px 5px 0 9px;
          color: var(--color-base);
          right: 9px;
          top: 12px;
        }
      }
    }
    .item .title::before,
    .item:last-child .title.active::before {
      content: '';
      position: absolute;
      top: 12px;
      left: -26px;
      height: 100%;
      width: 2px;
      border-left: 2px dotted var(--color-primary);
    }
    .item:last-child .title::before {
      height: 40px;
    }
    // .content::before {
    //   content: '';
    //   position: absolute;
    //   top: 25px;
    //   left: 18px;
    //   height: 105%;
    //   width: 2px;
    //   border-left: 2px dotted var(--color-primary);
    // }
    .content:last-child::before {
      height: 95%;
    }
    .content:last-child .timeline-item {
      padding-top: 20px;
      margin-bottom: 40px;
    }
    .content {
      position: relative;
      ul {
        padding-left: 10px !important;
      }
    }
  }
}