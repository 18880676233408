/* Section */
.cw-section {
  padding: 0 27px;

  .cw-section--addQuestions {
    padding: 15px 68px 15px 11px;
  }

  &--homepage-body {
    background-color: #e6f7ff;
    height: auto;
    color: #007dbb;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    padding-left: 0;
    // width: 85%;
    // margin: 0 0 0 6%;
  }

  &--request-section {
    // margin-bottom: 10%;
    // margin-left: 11%;
    //padding-left: 10%;
    padding-left: 50px;
  }
  .home-page-custom-height {
    @include media-breakpoint-up(lg) {
      min-height: 110px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 90px;
    }
    p {
      letter-spacing: 0.5px;
    }
  }
}
