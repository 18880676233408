.history-col-heading {
  height: 24px;
  width: 149px;
  color: #373a3c;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.heading-row {
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0 -1px 0 0 #eceeef;
}

.history-col {
  color: #373a3c;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
}
.email-brief-history {
  .history-col {
    font-size: 14px;
  }
  .cw-striped-row {
    padding: 14px 0px;
  }

  .cw-striped-email-brief-row {
    padding: 7px 0px;
    border-bottom: 1px solid #eceeef;
  }

  .circle-singleline {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: var(--text-xsmall);
    line-height: 30px;
    text-align: center;
    background: var(--color-base);
    color: var(--color-white);
    display: inline-block;
    margin-left: 5px;
  }
}
.email-brief-font {
  font-size: 12px;
}
.email-brief-view-more-font {
  font-size: 14px;
}
