.cw-color--primary {
  color: var(--color-primary);
}

.cw-bg--primary {
  background-color: var(--color-table);
}

.cw-bgpattern-primary {
  background-color: var(--color-table);
  box-shadow: 0 -1px 0 0 var(--color-border-table);
}

.cw-color-red {
  color: #ff0000;
}

.cw-color--saasheading {
  color: #007dbb;
}

.alert-update-message {
  background-color: #f7ebf9;
  color: #ad19cc;
}

.alert-knowmore-message {
  background-color: #fff;
  color: #007dbb;
}

.alert-completed-message {
  padding: 1% 2%;
  border-radius: 10px;
  width: 100% !important;
}

.guidelines-ext-link {
  text-decoration: underline;

  &:visited,
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}
