.cw-error {
  line-height: 22px;
  color: var(--color-error);
  margin-top: 9px;
  display: block;
  position: relative;

  // &:before {
  //     font-family: "Font Awesome 5 Free";
  //     content: "\f071";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 20px;
  //     height: 20px;
  // }
}