.cw-icon {
  color: var(--color-primary);
  cursor: pointer;

  &--cal {
    font-size: 1.15rem;
  }

  &--help {
    font-size: 1.25rem;
    line-height: var(--line-height-h3);
  }

  &--editAnswer {
    float: right;
    font-size: 20px;
    line-height: 24px;
    margin-right: 35px;
  }

  &--delete {
    float: none !important;
    font-size: 20px;
    line-height: 24px;
    margin-right: 35px;
    display: flex;
    @media (max-width: 1024px) {
      margin-right: 0 !important;
    }
  }
  &--addAnswer,
  &--deleteAnswer {
    float: right;
    font-size: 20px;
    line-height: 24px;
    margin-right: 35px;
  }

  &--delete {
    float: right;
    font-size: 20px;
    line-height: 24px;
    margin-right: 20px;
  }

  &--addAnswer,
  &--addConsent,
  &--deleteAnswer {
    position: absolute;
    right: -30px;
    bottom: 7px;
    margin: 0;
  }

  &--addConsent {
    top: 55px;
  }

  &--formid {
    margin: 0 0 0 -1%;
  }

  &__email {
    color: var(--color-base);
  }

  &--ml-5 {
    margin-left: 5px;
  }

  &--consent {
    color: var(--color-primary);
  }
}

.field-validation-icon {
  margin-top: 0.6875rem;
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
  position: relative;

  img {
    position: absolute;
    width: 20px;
    height: 20px;
    transition: opacity 0.75s ease-in 0.25s;
  }

  .hidden {
    opacity: 0;
  }
}
