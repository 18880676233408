/* Custom Checkbox */
[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='checkbox']:checked + label,
[type='checkbox']:not(:checked) + label {
  position: relative;
  padding-left: 31px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

[type='checkbox']:checked + label:before,
[type='checkbox']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
}

[type='checkbox']:checked + label:before {
  border: solid #007dbb;
}

[type='checkbox']:checked + label:after,
[type='checkbox']:not(:checked) + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #007dbb;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
}

[type='checkbox']:checked + label:after {
  opacity: 1;
}

[type='checkbox'][disabled]:checked + label:before {
  border: solid #d3d3d3;
}

[type='checkbox'][disabled]:checked + label:after,
[type='checkbox']:not(:checked) + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #d3d3d3;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Custom Radio Button */
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 31px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background: #fff;
}

[type='radio']:disabled + label:hover {
  cursor: not-allowed;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 7px;
  left: 5px;
  width: 10px;
  height: 10px;
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
  border-radius: 50%;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
}

[type='radio']:checked + label:after {
  opacity: 1;
}

[type='text']:disabled {
  background-color: #e9ecef;
}

input[name='startDate']::-webkit-input-placeholder,
input[name='endDate']::-webkit-input-placeholder,
input[name='endDateTime']::-webkit-input-placeholder {
  color: var(--color-base);
}

// File Input Control
.cw-striped-row .col-sm-4.file-input {
  flex-direction: row;
  justify-content: left;
  flex-flow: row wrap;

  label {
    color: var(--color-primary);
    cursor: pointer;
  }
}

.cw-error-focus {
  border: 1px solid red;
}
