.btn-new-communication {
  margin: 0 0 0 32%;
  width: 21%;
}

// .mobile-device-prev {
//   display: flex;
//   justify-content: center;
//   background-color: #F9F8F7;
//   .device-frame {
//     width: 200px;
//     position: relative !important;
//     .mob-content-area {
//       position: absolute !important;
//       top: 78px;
//       background-color: #EBEFF0;
//       left: 10px;
//       width: 240px;
//       padding: 10px 10px;
//       height: 482px;
//       .mob-text {
//         border-radius: 10px 10px 10px 0px;
//         background: #FEFEFE;
//         padding:5px 5px;
//         margin: 5px 0px;
//         color: black;
//       }
//     }
//   }
// }

.mobile-device-prev {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  .device-frame {
    width: 280px;
    height: 604px;
    //position: relative !important;
    border-radius: 50px;
    border: 10px solid #202020;
    background: #ebeff0;
    display: flex;
    flex-direction: column;
    .mob-header {
      height: 604px;
      width: 261px;
      height: 51px;
      flex-shrink: 0;
      border-radius: 40px 40px 0px 0px;
      background: #2266e3;
      display: flex;
      align-items: center;
      padding-top: 10px;
      .arrow-box {
        flex-grow: 1;
        flex-basis: 0;
        .arrow-icon {
          margin-left: 16px;
        }
      }
      .user-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        flex-basis: 0;
        .mob-number {
          color: #fff;
          text-align: center;
          font-size: 8px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 300% */
        }
      }
      .mob-space-box {
        flex-grow: 1;
        flex-basis: 0;
        width: 100%;
      }
    }
    .mob-content-area {
      background-color: #ebeff0;
      width: 100%;
      padding: 10px 10px;
      overflow-y: auto;
      border-radius: 0px 0px 40px 40px;
      //height: 482px;
      .samp-text {
        text-align: center;
        font-size: 8px;
        display: block;
        margin-bottom: 10px;
      }
      .sms-box {
        margin-bottom: 20px;
        .sms-date {
          text-align: center;
          font-size: 8px;
          display: block;
          //margin-bottom: 5px;
        }
        .sms-content {
          border-radius: 10px 10px 10px 0px;
          background: #fefefe;
          padding: 10px 10px;
          margin: 10px 0px;
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #4f4f4f;
          min-height: 56px;
          text-align: left;
          white-space: pre-wrap;
          overflow-wrap: break-word;
          word-wrap: break-word;

          -ms-word-break: break-all;
          /* Instead use this non-standard one: */
          word-break: break-word;

          /* Adds a hyphen where the word breaks, if supported (No Blink) */
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto;
        }
      }
      &::-webkit-scrollbar-track {
        margin-bottom: 10px; //for vertical scrollbar
      }
    }
  }
  .mobile-preview-note {
    color: var(--color-base);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  a {
    text-decoration: underline;
    pointer-events: none;
  }
}

.mobile-preview-overview {
  flex: 0;
  justify-content: flex-start;
  padding-top: 0px;
  align-items: flex-start;
}
