html,
body,
.skypink {
  font-size: var(--text-base-size);
  font-family: var(--font-primary);
  color: var(--color-base);
  background-color: #fff;
  scroll-behavior: smooth;
}
html,
body,
.pinkpurple {
  font-size: var(--text-base-size);
  font-family: var(--font-primary);
  color: var(--color-base);
  background: rgb(230,1,104);
  background: linear-gradient(0deg, rgba(230,1,104,0.5) 0%, rgba(31,54,199,0.5) 100%);
  scroll-behavior: smooth;
}

:focus {
  outline: none !important;
}

a {
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}
