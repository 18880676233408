.table {
  color: var(--color-base);
  border-bottom: 1px solid var(--color-border-table);

  border-radius: 10px;
  background-color: var(--color-background);
  padding: 0 10px;
  border-collapse: separate;
  border-spacing: 0 6px;
  caption {
    display: none;
  }

  th,
  td {
    border-color: var(--color-border-table);
    padding: 10px;
  }

  thead th {
    color: var(--color-text-light);
    border-bottom: 1px solid var(--color-border-table);
  }

  tbody th,
  tbody td {
    font-weight: 400;
    word-wrap: break-word;
    padding: 8px;

    &.center {
      vertical-align: middle;
    }

    &.max-width-column {
      max-width: 200px;

      @media (max-width: 992px) {
        max-width: 175px;
      }
    }

    a {
      font-weight: bold;
    }
  }

  tr td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  tr td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.table-striped tbody tr:nth-of-type(odd),
  &.table-hover tbody tr:hover {
    background-color: #fff;
  }

  &.table-striped-dashboard tbody tr {
    background-color: var(--color-white);
  }
}
