// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import './reactDateTime';
@import './bootstrap-overrides';
@import './base';
@import './spaces';
@import './section';
@import './table';
@import './colors';
@import './buttons';
@import './tooltip';
@import './icons';
@import './header';
@import './typography';
@import './pagination';
@import './modal';
@import './custom-form-controls';
@import './errors';
@import './creatable';
@import './communications-dashboard';
@import './utilities';
@import './react-tabs';
@import './print';
@import './sizing';
@import './toggle.scss';
@import './advancesearch.scss';
@import './authoring-access';
@import './release-note-communication';
@import './cw-status-timeline.scss';
@import './themes/skypink/variables';
@import './themes/pinkpurple/variables';
@import './fonts';
@import './email-briefing-style';
@import './overflow-menu';
@import './design-email';
@import './muilti-range-slider';
@import './page-layout';
@import './react-select';
@import './sidebar';
@import './sms-campaign';

// make page full height so footer sticks to bottom
#root {
  position: relative;
  min-height: 100vh;
}

main {
  padding-bottom: 300px;
}

main:has(+ .bee-editor-footer) {
  padding-bottom: 0;
}

.cw-container {
  // background: url('./images/footer-pattern.png') no-repeat center bottom #fff;
  background-size: 100% 145px;
  padding-bottom: 345px;
}

.refresh-icon {
  margin-bottom: 2px;
  margin-left: 9px;
  filter: brightness(0) saturate(100%) invert(34%) sepia(30%) saturate(3482%)
    hue-rotate(174deg) brightness(96%) contrast(101%);
}

.card-header-dnd {
  display: flex;
  strong {
    width: 100%;
    display: block;
    .saas-accordion-heading {
      max-width: 100%;
      display: block;
    }
  }
}
.container.desktop {
  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
}

.nonProdUrl-field {
  position: relative;
}

.qna-tab-overlay-custom {
  width: 96% !important;
  position: unset;
  min-height: auto !important;
  height: auto !important;
  margin: auto !important;
  .qna-tab-content {
    height: 35px;
  }
}
.overview-page-history .cw-section {
  padding: 0 22px;
}
.cw-promotigo-child .form-group:last-child {
  margin: 0;
}
@media (max-width: 1024px) {
  .cw-promotigo-child .form-group {
    margin-bottom: 0.5rem;
  }
  .bd-highlight {
    padding: 0 !important;
  }
  .cw-promotigo-container {
    margin: 0;
  }
  .form-group.description-text {
    margin-bottom: 0;
  }
}
